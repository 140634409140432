<template>
  <!-- 选择岗位组件 -->
  <div class="selStation">
    <van-nav-bar
      title="选择岗位"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-search
      v-model="value"
      shape="round"
      background="#fff"
      placeholder="请输入搜索关键词"
    />
    <!-- 面包屑导航 -->
    <div class="crumbs">
      <ul>
        <li v-for="(cb, cbIdx) in crumbList" :key="cbIdx">
          <span @click="tabClick(cb, cbIdx)">{{ cb.name }}</span>
          <van-icon name="arrow" v-if="crumbList.length - 1 != cbIdx" />
        </li>
      </ul>
    </div>
    <!-- 部门树部分 -->
    <ul class="deptTree" v-show="gwAry.length == 0">
      <li v-for="(tr, trIdx) in treeAry" :key="trIdx">
        <span>{{ tr.name }}</span>
        <span @click="next(tr.name)">
          <svg-icon icon-class="selStation_next"></svg-icon>
          下级
        </span>
      </li>
    </ul>
    <!-- 岗位树部分 -->
    <van-radio-group v-model="radio" v-show="gwAry.length > 0">
      <van-cell-group>
        <van-cell
          v-for="(gw, gwIdx) in gwAry"
          :key="gwIdx"
          :title="gw.name"
          clickable
          @click="selWork(gw.name)"
        >
          <template #right-icon>
            <van-radio :name="gwIdx" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: 0,
      value: "",
      crumbList: [{ name: "华跃科技" }],
      treeAry: [{ name: "业务部", child: true },
            { name: "客服部", child: true },
            { name: "行政部", child: true },
            { name: "研发部", child: true },
            { name: "生成部", child: true },
            { name: "五金部", child: true },
            { name: "财务部", child: true },],
      scrollX: 0,
      gwAry: [],
      leftToPath:'',
      gwQuery:null //选择岗位后返回的参数对象
    };
  },
  watch: {
    crumbList: {
      handler(value) {
        let cbWidth = 0; //面包屑的总宽
        let ziUlWidth = 0; //面包屑Ul盒子的宽度
        let crubms = document.querySelector(".crumbs");
        let ziUl = crubms.children[0];
        this.$nextTick(() => {
          cbWidth = crubms.offsetWidth;
          ziUlWidth = ziUl.offsetWidth;
          if (ziUlWidth > cbWidth) {
            // 说明内容超出总宽度，这时候需要ul向左边移动一个li单位的宽度
            let len = ziUl.children.length - 1;
            this.scrollX = ziUlWidth - cbWidth;
            crubms.scroll(this.scrollX, 0);
          }
        });
      },
      deep: true,
    },
  },
    beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.leftToPath = from.fullPath;
      console.log(from,'form')
      vm.gwQuery=from.query
    });
  },
  methods: {
    selWork(name){
      this.gwQuery.gw=name
      this.$router.push({path:'/confirmEntry',query:this.gwQuery})
    },
    tabClick(item, index) {
      if (index != this.crumbList.length - 1) {
        this.crumbList.splice(index + 1);
        if (item.name == "研发部") {
          this.treeAry = [];
          this.gwAry = [];
          this.gwAry = [
            { name: "前端开发", child: false },
            { name: "java开发", child: false },
            { name: "UI设计", child: false },
            { name: "大数据", child: false },
            { name: "测试", child: false },
            { name: "研发总监", child: false },
            { name: "文案", child: false },
          ];
        }else if (item.name == "华跃科技") {
          this.treeAry = [];
          this.gwAry = [];
          this.treeAry = [
            { name: "业务部", child: true },
            { name: "客服部", child: true },
            { name: "行政部", child: true },
            { name: "研发部", child: true },
            { name: "生成部", child: true },
            { name: "五金部", child: true },
            { name: "财务部", child: true },
          ];
        }
      } else {
      }
    },
    onClickLeft() {
      this.$router.push(this.leftToPath);
    },
    next(nextName) {
      this.crumbList.push({ name: nextName });

      if (nextName == "研发部") {
        this.treeAry = [];
        this.gwAry = [];
        this.gwAry = [
          { name: "前端开发", child: false },
          { name: "java开发", child: false },
          { name: "UI设计", child: false },
          { name: "大数据", child: false },
          { name: "测试", child: false },
          { name: "研发总监", child: false },
          { name: "文案", child: false },
        ];
      } else {
        this.treeAry = [];
        this.gwAry = [];
        this.treeAry = [
          { name: "业务部", child: true },
          { name: "客服部", child: true },
          { name: "行政部", child: true },
          { name: "研发部", child: true },
          { name: "生成部", child: true },
          { name: "五金部", child: true },
          { name: "财务部", child: true },
        ];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.selStation {
  height: 100vh;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    margin-bottom: 24px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-size: 32px;
      }
    }
  }
  .van-search {
    margin-bottom: 24px;
  }
  .crumbs {
    overflow: auto;
    background: #fff;
    height: 108px;
    ul {
      box-sizing: border-box;
      overflow: auto;
      display: flex;
      white-space: nowrap;
      height: 100%;
      padding: 0 30px;
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      font-size: 32px;
      color: #2b8df0;
      background: #fff;
      li {
        float: left;
        height: 100%;
        display: flex;

        align-items: center;
      }
      li:last-child {
        color: #999;
      }
    }
  }
  .deptTree {
    padding-top: 24px;
    box-sizing: border-box;
    height: calc(100% - 356px);
    overflow: auto;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 108px;
      font-size: 32px;
      padding: 0 30px;
      background: #fff;
      margin-bottom: 24px;
      box-sizing: border-box;
      span:last-child {
        color: #2b8df0;
      }
    }
  }
  .van-radio-group {
    padding-top: 24px;
    box-sizing: border-box;
    height: calc(100% - 356px);
    overflow: auto;
  }
}
</style>
